<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <!-- <div class="row">
            <div class="col-md-6 d-flex align-items-stretch mb-3">
                <div class="row flex-grow">
                    <div class="col-12 d-flex">
                        <div class="text-left">
                            <b-btn v-on:click="resetForm" variant="primary">
                                {{ $t('add-location-btn') }}
                            </b-btn>
                        </div>
                        <div>
                            <b-button
                            :class="visible ? null : 'collapsed'"
                            :aria-expanded="visible ? 'true' : 'false'"
                            aria-controls="collapse-4"
                            @click="visible = !visible"
                            variant="primary"
                            >
                            Toggle Collapse
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="modalmd" ref="vuemodal" :title="title" size="md" hide-footer>
                <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
                    <b-form-group :label="$t('name*')" label-for="name">
                        <b-form-input type="text" id="name" name="name" autocomplete="new-password" :placeholder="$t('name-placeholder')" v-model="$v.form.name.$model" :state="$v.form.name.$dirty ? !$v.form.name.$error : null" aria-describedby="input-1-live-feedback">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <div class="submit-btns">
                        <b-button class="save-btn" type="submit" variant="success" :disabled="isDisabled">
                            <span v-if="form.id == ''">{{ $t('submit-btn') }}</span>
                            <span v-else>{{ $t('update-btn') }}</span>
                        </b-button>
                    </div>
    
                </form>
            </b-modal>
        </div> -->
        <!-- <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <b-collapse id="collapse-4" v-model="visible" class="mt-2">
                    <b-card>
                        I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!I should start open!
                        <div>
                            <b-button type="submit" variant="primary">
                                <span v-if="form.id == ''">{{ $t('Filter') }}</span>
                                <span v-else>{{ $t('update-btn') }}</span>
                            </b-button>
                        </div>
                    </b-card>
                </b-collapse>   
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('Archived Locations') }}</h4>
                            <div class="header-buttons">
                                <button class="btn btn-primary" @click="showLocations">{{ $t('Go Back') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}"
                            :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }" :rows="rows" :columns="columns" :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                <span
                                    v-if="props.column.field === 'action'"
                                    class="text-nowrap"
                                >
                                <!-- <toggle-button
                                    v-model="locations.find(p => p.id === props.row.id).show"
                                    @change="changeStatus(props.row.id, $event)"
                                    :sync="true"
                                    class="mr-1 switchColor"/>
                                <b-button size="sm"
                                    @click="editLocation(props.row.id)"
                                    class="mr-1 btn-info text-white">
                                    {{ $t('edit-btn') }}
                                </b-button>
                                <b-button size="sm"
                                    @click="goToFixLocations(props.row.id)"
                                    class="mr-1 btn-info text-white">
                                    {{ $t('fix-locations') }}
                                </b-button>
                                <b-button size="sm" @click="deleteLocation(props.row.id)"
                                            class="mr-1 btn-danger text-white">
                                    {{ $t('delete-btn') }}
                                </b-button> -->
                                <b-button size="sm" v-if="props.row.status == 2"
                                    class="mr-1 btn-disabled text-white btn-restore" @click="restoreLocation(props.row.id)">
                                    {{ $t('restore-btn') }}
                                </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs,
        between
    } from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'ArchivedLocations',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    name: "",
                    id: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    show: true,
                },
                isDisabled: false,
                title: i18n.t('add-location'),
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [{
                        field: 'id', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }],
    
                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [{
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Deleted At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                locations: [],
                visible: true
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required,
                },
            },
        },
        methods: {
            restoreLocation(e) {
            console.log('e :', e);

                this.$swal({
                    title: 'Are you sure?',
                    text: "You want to restore Location!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Restore it!'
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            id: e
                        }
                        API.restoreLocation(
                            e,
                            data => {
                                this.loadItems();
                                // this.$Snotify.success(data.message)
                                this.$snotify.success('Record restored successfully.');
                                // this.$swal(
                                //     'Restore!',
                                //     'Record has been restored.',
                                //     'success'
                                // )
                            },
                            err => {
                            })
                    }
                })
            },
            showLocations() {
                this.$router.push({
                    name: "locations",
                });
                
            },
            // changeStatus(id, event) {
            //     console.log(id, event.value);
            //     const data = {
            //         id: id,
            //         show: event.value === true ? 1 : 0
            //     };
            //     API.updateLocationStatus(
            //         data,
            //         data => {
            //             this.loadItems();
            //             if (data.status === 200) {
            //                 this.$snotify.success(data.message);
            //             }
            //         },
            //         err => {}
            //     );
            // },
            // deleteLocation(e) {
            //     this.$swal({
            //         title: 'Are you sure?',
            //         text: "You won't be able to revert this!",
            //         type: 'warning',
            //         showCancelButton: true,
            //         confirmButtonColor: '#3085d6',
            //         cancelButtonColor: '#d33',
            //         confirmButtonText: 'Yes, delete it!'
            //     }).then((result) => {
            //         if (result.value) {
            //             // let data = {
            //             //     id: e
            //             // }
            //             API.deleteLocation(
            //                 e,
            //                 data => {
            //                     this.loadItems();
            //                     this.$snotify.success('Record has been deleted.');
            //                     // this.$swal(
            //                     //     'Deleted!',
            //                     //     'Record has been deleted.',
            //                     //     'success'
            //                     // )
            //                 },
            //                 err => {})
            //         }
            //     })
            // },
            // editLocation(e) {
            //     this.$v.form.$reset();
            //     this.$root.$emit("bv::show::modal", "modalmd");
            //     this.title = i18n.t('edit-location');
            //     const data = {
            //         id: e,
            //         client_id: JSON.parse(localStorage.getItem('client_id'))
            //     }
            //     API.getEditLocation(
            //         data, data => {
            //             if(data.status == 200) {
            //                 this.form.name = data.data.location.name;
            //                 this.form.id =  data.data.location.id;
            //                 this.subForm.location_id = data.data.location.id;
            //                 this.subForm.fixLocations = data.data.fixLocations;
            //                 this.form.show = (data.data.location.show == 1) ? true : false;
            //             }
            //         },
            //         err => {}
            //     )
            // },
            goToFixLocations(e) {
                this.$router.push({
                    name: "fix_locations",
                    params: { location_id: e }
                });
            },
            // onSubmit() {
            //     this.$v.form.$touch();
            //     if (this.$v.form.$anyError) {
            //         return;
            //     }
            //     if (this.form.name != '') {
            //         this.isDisabled = true;
            //         API.addEditLocations(
            //             this.form,
            //             data => {
            //                 this.$refs["vuemodal"].$refs['close-button'].click();
            //                 if (data.status === 200) {
            //                     this.$snotify.success(data.message);
            //                 } else {
            //                     this.$snotify.error(data.message);
            //                 }
            //                 this.loadItems();
            //                 this.isDisabled = false;
            //                 this.form.name = '';
            //                 this.form.id = '';
            //                 this.form.show = true;
            //                 this.$v.form.$reset();
            //                 // this.fixedLocations= [];
    
            //             },
            //             err => {
            //                 this.isDisabled = false;
            //                 this.$snotify.error(err, {
            //                     timeout: 2000,
            //                     showProgressBar: false,
            //                     closeOnClick: true,
            //                     pauseOnHover: true,
            //                 });
    
            //             }
            //         )
            //     } else {
            //         this.$snotify.warning('Please fill required fields', {
            //             timeout: 2000,
            //             showProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //         });
            //         this.isDisabled = false;
            //     }
            // },
            loadItems() {
                this.isLoading = true;
                API.getArchivedLocations(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.rows.forEach(q => {
                            const data = {
                                id: q.id,
                                // show: q.show === 1 ? true : false
    
                            };
                            this.locations.push(data);
                        });
                    },
                    err => {}
                )
            },
            // resetForm() {
            //     this.title = i18n.t('add-location');
            //     this.form.name = '';
            //     this.form.id = '';
            //     this.$v.form.$reset();
            //     this.$root.$emit("bv::show::modal", "modalmd");
            // },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
    
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
    
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
        },
        computed: {},
        created() {
            // this.getFixLocations();
        },
        mounted() {
            this.isLoading = true;
            this.loadItems();
        }
    }
</script>


<style>
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    .submit-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-right: auto;
        margin: 0 auto;
    }

    .new-location {
        float: right;
    }
    .save-btn {
        float: left;
    }
    .btn-disabled {
        width: 100px;
    }
    .btn-restore {
        width: 70px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
</style>
